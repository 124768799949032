import React, { useState, useEffect } from 'react'
import { Box, Text, Button, Badge, Flex } from '@chakra-ui/react'
import { AddIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Season, useGetGames } from '../data'
import { ManageSeason } from './ManageSeason'
import { Link, useHistory } from 'react-router-dom'
import { OfficialPartnerUpload } from '../components/OfficialPartnerUpload'
import { useAuthenticatedUser } from '../Authentication'

export type SeasonPageName =
    | 'prizing'
    | 'expert_tippers'
    | 'add_rounds'
    | 'winners'

export function SeasonForm({
    seasonId,
    page,
}: {
    seasonId: string
    page?: SeasonPageName
}) {
    const { data: games, refetch } = useGetGames()
    const [seasonToManage, setSeasonToManage] = useState<Season>()
    const history = useHistory()

    const user = useAuthenticatedUser()

    useEffect(() => {
        if (seasonId && games) {
            const [sport, seasonName] = seasonId.split('_')
            const season = games.find(
                (x) =>
                    x.season === seasonName &&
                    x.sport.toLocaleLowerCase() === sport.toLocaleLowerCase(),
            )
            setSeasonToManage(season)
        } else {
            setSeasonToManage(undefined)
        }
    }, [games, seasonId])

    if (seasonToManage) {
        return (
            <ManageSeason
                season={seasonToManage}
                page={page}
                refetch={() => refetch()}
            />
        )
    }

    return (
        <Box px="10">
            <Box height={100}>
                <Text fontSize={'xx-large'}>Seasons</Text>
                <Button
                    colorScheme="blue"
                    leftIcon={<AddIcon />}
                    onClick={() => history.push('/seasons/newseason')}
                    disabled={user?.hasCommercialRole()}
                >
                    Create new season
                </Button>
            </Box>

            <Box height="0.5px" backgroundColor="grey" />

            <Box mt="10">
                <Text fontSize={'x-large'} fontWeight="bold">
                    AFL
                </Text>
                <Box display="flex" dir="row">
                    {games
                        ?.filter((g) => g.sport === 'AFL')
                        .map((g) => (
                            <SeasonCard
                                season={g}
                                key={`${g.sport}-${g.season}`}
                            />
                        ))}
                </Box>
            </Box>

            <Box mt="10">
                <Text fontSize={'x-large'} fontWeight="bold">
                    AFLW
                </Text>
                <Box display="flex" dir="row">
                    {games
                        ?.filter((g) => g.sport === 'AFLW')
                        .map((g) => (
                            <SeasonCard
                                season={g}
                                key={`${g.sport}-${g.season}`}
                            />
                        ))}
                </Box>
            </Box>

            <Box mt="10">
                <Text fontSize={'x-large'} fontWeight="bold">
                    WAFL
                </Text>
                <Box display="flex" dir="row">
                    {games
                        ?.filter((g) => g.sport === 'WAFL')
                        .map((g) => (
                            <SeasonCard
                                season={g}
                                key={`${g.sport}-${g.season}`}
                            />
                        ))}
                </Box>
            </Box>

            <Box mt="10">
                <Text fontSize={'x-large'} fontWeight="bold">
                    Cricket
                </Text>
                <Box display="flex" dir="row">
                    {games
                        ?.filter((g) => g.sport === 'Cricket')
                        .map((g) => (
                            <SeasonCard
                                season={g}
                                key={`${g.sport}-${g.season}`}
                            />
                        ))}
                </Box>
            </Box>

            <OfficialPartnerUpload />
        </Box>
    )
}

function SeasonCard({ season }: { season: Season }) {
    const colorMap = {
        'in-progress': 'green',
        'not-started': 'orange',
        closed: 'red',
    }
    return (
        <Link to={`/seasons/${season.sport}_${season.season}`}>
            <Flex
                borderWidth="1px"
                borderColor="#E2E8F0"
                borderRadius="10px"
                width={300}
                height={120}
                direction="column"
                justify="center"
                p="2"
                mr="5"
            >
                <Box textAlign="right">
                    <Badge colorScheme={season.published ? 'green' : 'red'}>
                        {season.published ? 'Published' : 'Not Published'}
                    </Badge>
                </Box>
                {season.locked && (
                    <Box textAlign="right">
                        <Badge colorScheme="red">Locked</Badge>
                    </Box>
                )}
                <Box flexDir="row" display="flex" alignItems="center">
                    <Text fontSize="large" flex="1">
                        {season.sport} {season.season}
                    </Text>

                    <ChevronRightIcon />
                </Box>

                <Box textAlign="right">
                    <Badge colorScheme={colorMap[season.status] || 'red'}>
                        {season.status}
                    </Badge>
                </Box>
            </Flex>
        </Link>
    )
}
